import { FilterAction } from '@common/entities';
import { AmoCrmFieldType } from '@common/amoCrm/entities';
import { FieldTypesToActionsMap } from '../common';

const numbersActions = [
    FilterAction.EQUALS,
    FilterAction.IN_RANGE,
];

const identityFilters = [
    FilterAction.EQUALS,
    FilterAction.C_IN,
];

const statusesFilters = [
    FilterAction.EQUALS,
    FilterAction.C_IN,
];

const strFilters = [
    FilterAction.EQUALS,
    FilterAction.C_IN,
];

export const AmoCrmFieldTypesToActionsMap: FieldTypesToActionsMap = {
    'default': [
        FilterAction.EQUALS,
    ],
    [AmoCrmFieldType.STRING]: strFilters,
    [AmoCrmFieldType.INT]: numbersActions,
    [AmoCrmFieldType.DATE_U_TIMESTAMP]: numbersActions,
    [AmoCrmFieldType.IDENTITY]: identityFilters,
    [AmoCrmFieldType.USER]: identityFilters,
    [AmoCrmFieldType.STATUS]: statusesFilters,
};
